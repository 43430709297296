import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import imgHero from "../images/gfx/hero-article.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-2.jpg"

import imgFuelPump from "../images/fuel-pump.jpg"
import imgStopLoss from "../images/stop-loss.jpg"
import imgPassengerTicket from "../images/passenger-mobile-ticket-seated-coach.jpg"
import imgGraph from "../images/graph.jpg"
import imgProfileChrisBell from "../images/chris-bell-profile-square.jpg"
import "./index.css";
import "./case-study.css";
import "./article.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from "../components/nav"
import Footer from "../components/footer"
import Contact from "../components/contact"

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="How technology can save fuel on your school bus" />
        <Nav activeTab="blog" />
        <div>
          <div className="case-study">

              <div className="hero-article" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1 className="blog-headline">How technology can save fuel on your school bus</h1>
              </div>

              <div className="mobile-hero-article" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1 className="blog-headline">How technology can save fuel on your school bus</h1>
              </div>

              <div>

                <div className="article">
                  <div className='article-intro article-inner'>
                    <p>With the recent surge in fuel prices and concern about further increases, it’s a good time to revisit whether fuel consumption is costing your business more than it should.</p>
                    <p>We’ve partnered with <a className="link" href="https://www.pinpointers.com/" target="_blank">vehicle tracking</a> provider, Pinpointers, to examine how using affordable technology on your school bus can help you save money by reducing the amount of fuel needed and making the most of the fuel used.</p>
                    <p>Whether you aspire to eventually operate an exclusively electric fleet or not, this guide is packed full of practical improvements you can make today to save energy and money.</p>
                    <p><img src={imgFuelPump} alt="" /></p>
                  </div>

                  <div className='article-inner'>

                    <h2>1. Optimise vehicle allocation</h2>
                    <p>Vehicle management software can help you keep track of allocations so you know in advance what vehicles are available for use. Whilst this software is becoming standard amongst operators, many still lack visibility of how well utilised their school bus actually is.</p>
                    <p>If operators only use cash systems or get a simple day rate from the school/local authority, often they will have no idea how busy their service will be from day to day. This can either lead to overcrowding issues, or poor fuel economy by inefficiently allocating bigger and thirstier vehicles.</p>
                    <p>Without up-to-date data on actual usage that’s easy to access, operators also have no idea who travelled, which reduces their ability to understand travel patterns. In particular with annual and term passes, operators may have an indication of how busy their services should be - but they don’t know how busy they actually are. This disconnect can be surprisingly sizable; affected by the occasional lift in from mum or dad, travel that’s not required because of after school clubs, and then of course general school absence. Did you know that the last <a className="link" href="https://explore-education-statistics.service.gov.uk/find-statistics/pupil-absence-in-schools-in-england-autumn-term" target="_blank">official Autumn term recorded 11.7%* absence rates</a> for state-funded pupils in England?</p>
                    <p>Using a system like <Link className="link" to="/">ShuttleID</Link> can help you understand not only who should be travelling but importantly who is actually travelling. Ahead of time you’ll understand the exact numbers expected to travel through passes that have been purchased online or issued through the system, and post-journey you’ll have hard data on who is or isn’t travelling, making it possible to make informed decisions about vehicle allocations.</p>
                    <p><em>* 4.7% regular absences and 7% covid related absences</em></p>

                    <h2>2. Amalgamate school bus services</h2>
                    <img src={imgStopLoss} alt="" />
                    <p>If you operate multiple routes to a school, it could make sense to combine some of these school bus services into one. Although this can prove unpopular with some parents, this represents not only a big saving in fuel, but also has the advantage of freeing up an extra driver whilst they are in short supply.</p>
                    <p>Using technology like ShuttleID can help your decision making by giving you data to combine services in the least disruptive way. With a modern system that helps manage school bus passes and tickets, you will have clarity over which bus stops are used, how popular they are and how this translates into real usage across every individual journey.</p>
                    <p>Operators using a system like ShuttleID are often surprised by the insight this data gives them. For example, finding that actual bus stop usage is more popular on certain days or times, regardless of whether or not the travel is already paid for. Using this data, operators can make big savings in the least disruptive way possible.</p>

                    <h2>3. Maximise capacity</h2>
                    <img src={imgPassengerTicket} alt="Save time by selling bus passes online" />
                    <p>Taking steps to reduce the amount of fuel needed is important but equally so is maximising your fuel commercially by ensuring your school bus is as full as possible, each journey. If you’re paid per passenger, then the maths is simple - the aim is to fill every seat per journey. Even operators receiving a simple day rate per vehicle can benefit; by being able to <Link className="link" to="/case-study-cambridge-buses-help-local-authority-save-time-money/">offer less vehicles to complete the same job</Link> it can make your offer unbeatable in a tender process.</p>
                    <p>Data plays a big role in being able to maximise your capacity. A system like ShuttleID can help you fill spare seats, by offering the flexibility of single, return, week, AM only, PM only and flexi tickets. These options give flexibility to parents that may have been difficult to offer previously, in a way that still keeps you in control and so that you’re not at risk of overcrowding. Rather than baffling drivers with unlimited ticket variations to inspect, an affordable scanner device validates tickets automatically and accurately. </p>
                    <p>For routes already at theoretical capacity, the data that ShuttleID collects helps operators safely oversell should they wish to squeeze out any unrealised revenue. The waiting list feature also helps operators plan for the following term and immediately replace any unexpected mid-year leavers, helping ensure that any fuel used still retains the best profit margin possible.</p>

                    <h2>4. Park vehicles in between journeys</h2>
                    <p>If you operate multiple routes into a school, rather than all vehicles returning to the depot after the morning journey, it may be more commercially feasible to keep them parked up close to the school. With your drivers instead returning to the depot in a single vehicle, and using one vehicle to collect them for the afternoon run too, this can save a lot of fuel across the 380 journeys per parkable vehicle per year.</p>
                    <p>With a Pinpointers vehicle tracking device installed, not only will you have the security of knowing the vehicles haven’t been moved or stolen, but the technology can also tell you if the main battery is going flat and give you an early warning. Faulty alternators and ageing batteries don’t give you much warning when they are going to fail, but a system like Pinpointers can, by monitoring the external voltage level at all times.</p>

                    <h2>5. Improve driver efficiency</h2>
                    <img src={imgGraph} alt="" />
                    <p>When it comes to fuel efficiency the best tool at your disposal is a tracking and telemetry device. The tracking part tells you where the vehicle is now and where it has been through GPS readings, and the telemetry data tells you about harsh driving behaviour, such as harsh acceleration, braking and cornering using an internal accelerometer.</p>
                    <p>The Pinpointers Tracking system gives you easy visibility of your vehicles current locations and historical journeys, giving you the chance to avoid traffic jams in real time and to optimise your routes over time.</p>
                    <p>By looking at harsh driving you can see which drivers might benefit from further training and specifically fuel efficient driving, reducing your overall fuel costs.</p>

                    <h2>6. Cut engine idling</h2>
                    <p>Engine idling has been back under the microscope recently, with many local authorities reminding drivers that under Section 42 of the Road Traffic Act 1988, Rule 123 of the Highway Code stating “You must not leave a vehicle’s engine running unnecessarily while that vehicle is stationary on a public road”, which is an enforceable offence with a fine. This is particularly the case outside schools and colleges, where many of them are now creating “idle-free zones” to protect the health of children and students.</p>
                    <p>The Pinpointers Tracking system uses a two stage test to determine if a vehicle is genuinely idling its engine to give 100% certainty that it is happening within just 30 seconds. With one click of the mouse you can view all vehicles in an idling condition and if they are in idle-free zones, allowing you to contact the driver and remind them to turn off the engine.</p>
                    <p>Cutting engine idling can save an enormous amount of wasted fuel, with a full size bus or coach consuming as much as a gallon an hour, it’s a quick win to keep idling to a minimum.</p>

                    <div className='author' style={{ border: '1px solid #d5e6f5', background: '#f3f9ff', marginBottom: '10px' }}>
                      <div className='author__img'>
                        <img src={imgProfileChrisBell} alt="Chris Bell" />
                      </div>
                      <div className='author__quote' style={{  }}>
                        <p style={{ marginBottom: 10 }}><strong>About the author</strong></p>
                        <p style={{ marginBottom: 10 }}>Chris Bell is a director at ShuttleID, an affordable cashless ticketing system designed to make managing home-to-school services simple.</p>
                        <p style={{ marginBottom: 10 }}>During a 15 year career in technology, Chris worked on many websites and systems for coach companies and saw the issues of managing school buses firsthand, leading to the launch of ShuttleID.</p>
                        <p style={{ margin: 0 }}>Email: <a className="link" href="mailto:info@shuttleid.uk">info@shuttleid.uk</a> | Phone: 0333 344 9868</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage